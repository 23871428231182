<template>
  <div id="print" class="user-list">
    <el-button type="primary" style="margin-bottom: 10px" @click="goback">返回</el-button>
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="elevator/archive/iot/page">
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="realEstateName" label="楼盘楼宇" align="center" width="170">
        <template slot-scope="scope">
          {{scope.row.realEstateName+'-'+scope.row.buildingName}}
        </template>
      </el-table-column>
      <el-table-column prop="elevatorName" label="电梯在使用单位内部编号" align="center" width="170"></el-table-column>
      <el-table-column prop="regCode" label="注册代码" align="center" width="170"></el-table-column>
      <el-table-column prop="factoryCode" label="出厂编号" align="center" width="170"></el-table-column>
      <el-table-column prop="protocolSourceDesc" label="协议来源-设备型号" align="center">
        <template slot-scope="scope">
          {{scope.row.protocolSourceDesc+'-'+scope.row.deviceModel}}
        </template>
      </el-table-column>
      <el-table-column prop="deviceName" label="设备编号-设备端口" align="center">
        <template slot-scope="scope">
          {{scope.row.deviceName+'-'+scope.row.devicePort}}
        </template>
      </el-table-column>
      <el-table-column label="是否在线-上次心跳时间" align="center" width="90">
        <template slot-scope="scope">
          <el-tag :type="scope.row.online?'success':'info'">{{scope.row.online?'在线':'离线'}}</el-tag>{{scope.row.lastHeartbeatTime}}
        </template>
      </el-table-column>
    </vm-table>
  </div>
</template>
<script>

  import {getWidth} from "@/util";
  import print from  "print-js";
  export default {
    data() {
      return {
        loading:false,
        filter: {
          online:false,
          realEstateName:"",

        },
        senior: false,
        data:[],
        width:200,
        visible:false,
      };
    },
    mounted() {
      if (this.$route.query.realEstateName) {
        this.filter.realEstateName = this.$route.query.realEstateName;
      }
      this.getList(1);
      this.getTreeList();
    },
    methods: {
      goback() {
        this.$router.go(-1);
      },
      printSomething() {
        print({
          printable:"print",
          type:"html",
        });
      },
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
        this.visible=false;
      },
      deleteRow(row) {
        this.$confirm(this.$l("common.deleteTip", "确定删除{key}吗？", {key: row.name}), this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/elevator-archive/${row.id}`).then(() => {
            this.getList(-1);
            this.$message.success(row.name + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
      gotoMonitor(elevatorId) {
        let routeUrl = this.$router.resolve({
          path: "/monitor/LiftMonitor",
          query: {elevatorId},
        });
        window.open(routeUrl.href, "_blank");
      },
      goToDetail(elevatorId) {
        this.$router.push({path:"/elevator/Elevatordetail",query:{elevatorId}});
      },
      handleSeniorClick() {
        this.senior=!this.senior;
      },
      getTreeList() {
        let filter = this.filter;
        if (this.filterFun) {
          let filterCopy = JSON.parse(JSON.stringify(filter));
          filter = this.filterFun(filterCopy);
        }
        let http =  this.$http.get("/basic/district/tree/locate-and-with-real-estate");
        http.then(data => {
          this.data = data;
          this.width=100+14*getWidth(data);
        }).catch(() => {
          this.status = "error";
        });
      },
      handleNodeClick(e) {

        if (e.other!=="district") {
          this.filter.realEstateName=e.label;
          this.getList(-1);
        }

      },

    },
  };
</script>
<style lang="scss" scoped>
.treeBox{
  border: 1px solid #E6E6E6;
  padding: 10px;
  margin-right: 10px;
  width: calc(100% - 1300px);
  max-height: 1080px;
  overflow-y: auto;
}
</style>
